<template>
  <div
    class="container mx-auto flex flex-col space-y-10 md:space-y-0 md:flex-row items-center justify-between mt-16 mb-44"
  >
    <div class="w-full md:w-1/2 space-y-4 md:space-y-8">
      <h1
        class="max-w-xl font-bold text-3xl md:text-6xl animate__animated animate__fadeInUp"
      >
        Flexible integration and active maintenance
      </h1>
      <p class="font-normal md:text-lg max-w-lg">
        Lorem support is an end-to-end service customer support solution
        provider, with comprehensively tailored services for addressing the
        (re)current and emerging business needs of small, medium, and large
        organizations.
      </p>
      <div class="flex flex-row items-center space-x-4">
        <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
        <BookACallButton />
      </div>
    </div>
    <div class="w-full md:w-1/2 flex justify-center">
      <div class="relative">
        <img
          src="../assets/images/home-view-header-img.png"
          class="object-cover"
          alt=""
        />
        <div class="hidden xl:block absolute -bottom-[80px] -left-[120px]">
          <div
            class="bg-auto-response text-white px-8 pt-10 w-[300px] h-80 space-y-6 object-even rounded-2xl"
          >
            <h3 class="font-medium text-xl">SERVICES</h3>
            <div class="flex flex-row items-center">
              <span
                @click="toggleTabs(1)"
                class="cursor-pointer"
                :class="{
                  'text-white px-8 py-2': openTab !== 1,
                  'rounded-sm px-8 py-2 text-white bg-Terracotta':
                    openTab === 1,
                }"
                >SAAS</span
              >
              <span
                @click="toggleTabs(2)"
                class="cursor-pointer"
                :class="{
                  'text-white px-8 py-2': openTab !== 2,
                  'rounded-sm px-8 py-2 text-white bg-Terracotta':
                    openTab === 2,
                }"
                >QAAS</span
              >
            </div>
            <div
              :class="{ hidden: openTab !== 1, block: openTab === 1 }"
              class="text-xs flex flex-col space-y-4"
            >
              <p>Phone Support</p>
              <p>Email/Chat</p>
              <p>I/T Support</p>
              <p>Virtual Assistant</p>
            </div>
            <div
              :class="{ hidden: openTab !== 2, block: openTab === 2 }"
              class="text-xs flex flex-col space-y-4"
            >
              <p>Quality Assurance</p>
              <p>Data Entry</p>
              <p>Data Generation &amp; Management</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";
import BookACallButton from "@/components/BookACallButton.vue";
export default {
  name: "HomeViewHeader",
  components: {
    GetAQuoteButton,
    BookACallButton,
  },
  data() {
    return {
      openTab: 1,
    };
  },
  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>
