<template>
  <div class="relative bg-GreenWhite">
    <img
      src="../assets/icons/our-specialist-left-border.svg"
      class="hidden md:block absolute top-[50%] -translate-y-2/4 -translate-x-2/4"
      alt=""
    />
    <img
      src="../assets/icons/our-specialist-fixed-bottom.svg"
      class="hidden md:block absolute bottom-0 right-0"
      alt=""
    />
    <div
      class="container max-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row md:space-x-10 py-24"
    >
      <div class="w-full md:w-1/2 flex justify-center">
        <img
          src="../assets/images/our-specialist-img.png"
          class="object-cover"
          alt=""
        />
      </div>
      <div class="w-full md:w-1/2 space-y-5 flex flex-col items-center">
        <p class="font-bold text-2xl md:text-3xl max-w-lg">
          Our specialists are best at ensuring that no part of your business
          encounters a lag.
        </p>
        <p class="text-md max-w-lg">
          Lorem Support provides you all of that and interestingly much more. We
          believe you are the best at what your business is known for (Core
          Tasks). It’s all about putting the best team together – not just in
          the core business Activities, but also in the “Non-Core” processes.
        </p>
        <p class="text-md max-w-lg">
          We re-define customer experience using our omni-channels; calls
          (inbound, outbound), Chats, Emails along with performing channel
          analysis to boost sales and increase Customer retention.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurSpecialistSolution",
};
</script>
