<template>
  <div class="container max-auto mt-44 mb-8 md:mb-16">
    <div class="w-full md:w-1/2 block md:hidden">
      <img
        src="../assets/images/phone-support-img.png"
        class="object-cover"
        alt=""
      />
    </div>
    <p class="font-bold text-3xl md:text-5xl md:leading-[4rem] max-w-3xl">
      Boost your business with tools to deliver the best
      <span class="text-Terracotta">physical and digital experience</span>
    </p>
  </div>
  <div
    class="container mx-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row my-20 md:space-x-10"
  >
    <div class="w-full md:w-1/2 space-y-6">
      <p class="font-bold text-3xl max-w-lg">Phone Support</p>
      <p class="text-md max-w-lg leading-[2rem]">
        Every successful business revolves around coherent and clear
        communication. From information flow to query resolution, let Lorem
        support your business by handling your inbound and outbound calls.
      </p>
      <div class="flex flex-col space-y-4 text-MistBlue">
        <div class="flex flex-row items-center space-x-2">
          <img src="../assets/icons/support-icon.svg" alt="" />
          <p>Handle collection & telemarketing calls</p>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <img src="../assets/icons/support-icon.svg" alt="" />
          <p>Handle warm leads & customer care calls</p>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <img src="../assets/icons/support-icon.svg" alt="" />
          <p>Handle all inbound & outbound business calls</p>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2 hidden md:block">
      <img
        src="../assets/images/phone-support-img.png"
        class="object-cover"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PhoneSupport",
};
</script>
