<template>
  <div class="mb-10">
    <Navbar />
    <LogisticsAndWareHousingHeader />
    <LogisticsAndWareGainAccess />
    <LeverageLoremSupport />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import LogisticsAndWareHousingHeader from "@/components/LogisticsAndWareHousingHeader.vue";
import LogisticsAndWareGainAccess from "@/components/LogisticsAndWareGainAccess.vue";
import LeverageLoremSupport from "@/components/LeverageLoremSupport.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "LogisticsAndWareHousingView",
  components: {
    Navbar,
    LogisticsAndWareHousingHeader,
    LogisticsAndWareGainAccess,
    LeverageLoremSupport,
    Footer,
  },
};
</script>
