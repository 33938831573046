<template>
  <div class="container mx-auto my-20">
    <div class="flex flex-row items-center space-x-4 mb-10">
      <div
        class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
      ></div>
      <h3 class="text-lg font-medium tracking-[3px]">PRICING</h3>
    </div>
    <p class="font-bold text-4xl md:leading-[3rem] mb-16 max-w-lg">
      Let's help you calculate your plan
    </p>

    <!-- Calculator -->
    <div class="w-full md:w-1/2">
      <label for="industry">Email</label>
      <div class="relative w-full border-none mt-2">
        <input
          v-model="args.email"
          placeholder="Email"
          class="border rounded-lg text-[#ABAFB1] px-4 py-3 w-full"
          type="text"
          name=""
          id=""
        />
      </div>
    </div>

    <div class="md:w-9/12 mb-80">
      <div
        class="flex flex-col space-y-10 md:spacey-0 md:flex-row md:space-x-10 items-end mb-12"
      >
        <div class="w-full md:w-1/2">
          <label for="industry">Industry</label>
          <div class="relative w-full border-none mt-2">
            <select
              v-model="args.industries"
              class="text-[#ABAFB1] cursor-pointer appearance-none border inline-block py-3 pl-3 pr-8 rounded-lg leading-tight w-full"
            >
              <option selected class="pt-6">Select industry</option>
              <option
                :value="industries.value"
                v-for="industries in industriez"
                :key="industries.value"
                class="pt-6"
              >
                {{ industries.label }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4"
            >
              <DropdownSelectIcon />
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <label for="industry">Time Zone</label>
          <div class="relative w-full border-none mt-2">
            <select
              v-model="args.timezone"
              class="text-[#ABAFB1] cursor-pointer appearance-none border inline-block py-3 pl-3 pr-8 rounded-lg leading-tight w-full"
            >
              <option class="pt-6">Select Time Zone</option>
              <option
                :value="timezone.value"
                v-for="timezone in timeZones"
                :key="timezone.value"
                class="pt-6"
              >
                {{ timezone.label }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4"
            >
              <DropdownSelectIcon />
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col space-y-10 md:spacey-0 md:flex-row md:space-x-10 items-end mb-12"
      >
        <div class="w-full md:w-1/2">
          <label for="workHours">Work Hours</label>
          <div class="flex flex-row space-x-3 mt-3">
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseWorkHours('NINE_TO_FIVE')"
              :class="{
                'text-Terracotta': args.workhours !== 'NINE_TO_FIVE',
                'bg-Terracotta text-white': args.workhours === 'NINE_TO_FIVE',
              }"
            >
              9 - 5
            </button>
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseWorkHours('TWENTY_FOUR_SEVEN_FIVE')"
              :class="{
                'text-Terracotta': args.workhours !== 'TWENTY_FOUR_SEVEN_FIVE',
                'bg-Terracotta text-white':
                  args.workhours === 'TWENTY_FOUR_SEVEN_FIVE',
              }"
            >
              24/7/5
            </button>
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseWorkHours('TWENTY_FOUR_SEVEN_THREE_SIX_FIVE')"
              :class="{
                'text-Terracotta':
                  args.workhours !== 'TWENTY_FOUR_SEVEN_THREE_SIX_FIVE',
                'bg-Terracotta text-white':
                  args.workhours === 'TWENTY_FOUR_SEVEN_THREE_SIX_FIVE',
              }"
            >
              24/7/365
            </button>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <input
            placeholder="Flexible (Specify)"
            class="border rounded-lg text-[#ABAFB1] px-4 py-3 w-full"
            type="text"
            name=""
            id=""
          />
        </div>
      </div>
      <div
        class="flex flex-col space-y-10 md:spacey-0 md:flex-row md:space-x-10 items-end mb-12"
      >
        <div class="w-full md:w-1/2">
          <label for="workHours">Terms</label>
          <div class="flex flex-row space-x-3 mt-3">
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseTerms('SHORT')"
              :class="{
                'text-Terracotta': args.term !== 'SHORT',
                'bg-Terracotta text-white': args.term === 'SHORT',
              }"
            >
              Short
            </button>
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseTerms('MEDIUM')"
              :class="{
                'text-Terracotta': args.term !== 'MEDIUM',
                'bg-Terracotta text-white': args.term === 'MEDIUM',
              }"
            >
              Medium
            </button>
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseTerms('LONG')"
              :class="{
                'text-Terracotta': args.term !== 'LONG',
                'bg-Terracotta text-white': args.term === 'LONG',
              }"
            >
              Long Term
            </button>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <label for="workHours">Service Type</label>
          <div class="flex flex-row space-x-3 mt-3">
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="serviceType('DEDICATED')"
              :class="{
                'text-Terracotta': args.servicetype !== 'DEDICATED',
                'bg-Terracotta text-white': args.servicetype === 'DEDICATED',
              }"
            >
              Dedicated
            </button>
            <button
              class="rounded-3xl border-2 border-Terracotta text-white w-40 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="serviceType('NON_DEDICATED')"
              :class="{
                'text-Terracotta': args.servicetype !== 'NON_DEDICATED',
                'bg-Terracotta text-white':
                  args.servicetype === 'NON_DEDICATED',
              }"
            >
              Non - Dedicated
            </button>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col space-y-10 md:spacey-0 md:flex-row md:space-x-10 items-end mb-12"
      >
        <div class="w-full md:w-1/2">
          <label for="workHours">Established Number of Agents</label>
          <div class="flex flex-row space-x-3 mt-3">
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseNumberOfAgents('ONE_TO_FOUR')"
              :class="{
                'text-Terracotta': args.estagents !== 'ONE_TO_FOUR',
                'bg-Terracotta text-white': args.estagents === 'ONE_TO_FOUR',
              }"
            >
              Up to 4
            </button>
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseNumberOfAgents('ONE_TO_TEN')"
              :class="{
                'text-Terracotta': args.estagents !== 'ONE_TO_TEN',
                'bg-Terracotta text-white': args.estagents === 'ONE_TO_TEN',
              }"
            >
              Up to 10
            </button>
            <button
              class="rounded-3xl border-2 border-Terracotta text-white md:w-36 px-3 py-2 h-fit hover:bg-Terracotta hover:text-white"
              @click="chooseNumberOfAgents('TEN_ABOVE')"
              :class="{
                'text-Terracotta': args.estagents !== 'TEN_ABOVE',
                'bg-Terracotta text-white': args.estagents === 'TEN_ABOVE',
              }"
            >
              10+
            </button>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <input
            placeholder="Flexible (Specify)"
            class="border rounded-lg text-[#ABAFB1] px-4 py-3 w-full"
            type="text"
            name=""
            id=""
          />
        </div>
      </div>

      <!-- Choose your services -->
      <div class="dropdown w-full">
        <button
          @click="openServices = !openServices"
          class="w-full md:w-1/2 flex flex-row justify-between border text-[#ABAFB1] py-3 px-3 rounded-lg items-center"
        >
          <span class="mr-1">Choose your services</span>
          <DropdownSelectIcon />
        </button>
      </div>
      <div
        class="z-50 dropdown-menu pt-3 w-3/4"
        :class="openServices ? 'd-block' : 'hidden'"
      >
        <div class="border rounded-lg bg-white p-4">
          <!-- SAAS -->
          <div v-for="service in mainServices" :key="service.server">
            <label>
              <div class="">
                <div class="flex items-center justify-between cursor-pointer">
                  <div class="flex items-center space-x-4 py-4">
                    <div class="flex items-center">
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          @click="
                            openSaas[service.server] = !openSaas[service.server]
                          "
                        />
                        <span class="checkbox__inner"></span>
                      </label>
                    </div>
                    <div class="text-sm md:text-2xl">
                      <span class="font-thin text-Gunsmoke">
                        {{ service.server }}
                      </span>
                    </div>
                  </div>

                  <span v-if="openSaas[service.server]"
                    ><AccordionTopArrow
                  /></span>
                  <span v-else> <AccordionBottomArrow /></span>
                </div>
              </div>
            </label>

            <!-- Sub SAAS -->
            <div
              :class="openSaas[service.server] ? 'd-block' : 'hidden'"
              class="pl-4"
            >
              <!-- Email Support -->
              <div v-for="opt in service.options" :key="opt.value">
                <label class="flex items-center justify-between cursor-pointer">
                  <div class="flex items-center space-x-4 py-3">
                    <div class="flex items-center">
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          @click="opt.active = !opt.active"
                        />
                        <span class="checkbox__inner"></span>
                      </label>
                    </div>
                    <div class="text-xs md:text-xl">
                      <span class="font-thin text-Gunsmoke">
                        {{ opt.label }}
                      </span>
                    </div>
                  </div>
                  <span v-if="opt.active"><AccordionTopArrow /></span>
                  <span v-else> <AccordionBottomArrow /></span>
                </label>

                <div :class="opt.active ? 'd-block' : 'hidden'" class="pl-4">
                  <div
                    v-for="sub in opt.subs"
                    :key="sub.value"
                    class="flex items-center space-x-4 py-1"
                  >
                    <div class="flex items-center">
                      <label class="checkbox">
                        <input
                          v-model="args.mainservice[service.server][opt.value]"
                          :value="sub.value"
                          @click="
                            args.mainservice[service.server][opt.value] =
                              args.mainservice[service.server][opt.value] ===
                              sub.value
                                ? null
                                : sub.value
                          "
                          type="radio"
                          class="checkbox__input"
                          title="Lawal"
                        />
                        <!-- 
                         

                         -->
                        <span class="checkbox__inner"></span>
                      </label>
                    </div>
                    <div class="text-xs md:text-lg">
                      <label
                        for="helper-checkbox-1"
                        class="font-thin text-Gunsmoke"
                      >
                        {{ sub.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="dropdown w-full">
        <button
          @click="openServices = !openServices"
          class="w-full md:w-1/2 flex flex-row justify-between border text-[#ABAFB1] py-3 px-3 rounded-lg items-center"
        >
          <span class="mr-1">Choose your services</span>
          <DropdownSelectIcon />
        </button>
        <div
          class="z-50 dropdown-menu pt-3"
          :class="openServices ? 'd-block' : 'hidden'"
        >
          <div class="border rounded-lg bg-white p-4">
            <div v-for="service in mainServices" :key="service.server">
              <label>
                <div class="">
                  <div class="flex items-center justify-between cursor-pointer">
                    <div class="flex items-center space-x-4 py-4">
                      <div class="flex items-center">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            class="checkbox__input"
                            @click="
                              openSaas[service.server] =
                                !openSaas[service.server]
                            "
                          />
                          <span class="checkbox__inner"></span>
                        </label>
                      </div>
                      <div class="text-sm md:text-2xl">
                        <span class="font-thin text-Gunsmoke">
                          {{ service.server }}
                        </span>
                      </div>
                    </div>

                    <span v-if="openSaas[service.server]"
                      ><AccordionTopArrow
                    /></span>
                    <span v-else> <AccordionBottomArrow /></span>
                  </div>
                </div>
              </label>
              <div
                :class="openSaas[service.server] ? 'd-block' : 'hidden'"
                class="pl-6"
              >
                <div v-for="opt in service.options" :key="opt.value">
                  <label
                    class="flex items-center justify-between cursor-pointer"
                  >
                    <div class="flex items-center space-x-4 py-3">
                      <div class="flex items-center">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            class="checkbox__input"
                            @click="opt.active = !opt.active"
                          />
                          <span class="checkbox__inner"></span>
                        </label>
                      </div>
                      <div class="text-xs md:text-xl">
                        <span class="font-thin text-Gunsmoke">
                          {{ opt.label }}
                        </span>
                      </div>
                    </div>
                    <span v-if="opt.active"><AccordionTopArrow /></span>
                    <span v-else> <AccordionBottomArrow /></span>
                  </label>
                  <div :class="opt.active ? 'd-block' : 'hidden'" class="pl-6">
                    <div
                      v-for="sub in opt.subs"
                      :key="sub.value"
                      class="flex items-center space-x-4 py-2"
                    >
                      <div class="flex items-center">
                        <label class="">
                          <input
                            v-model="
                              args.mainservice[service.server][sub.value]
                            "
                            :value="sub.value"
                            :checked="sub.isChecked"
                            type="radio"
                            @click="handleToggleOption(sub.value, opt.value)"
                            class="checkbox__input"
                          />
                          <span class="checkbox__inner"></span>
                        </label>
                      </div>
                      <div class="text-xs md:text-lg">
                        <label
                          for="helper-checkbox-1"
                          class="font-thin text-Gunsmoke"
                        >
                          {{ sub.label }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <br />
      <!-- <button @click="calculate" class="btn-inter flex items-center w-fit">
        <span class="btn-content text-[10px] md:text-base">CALCULATE</span>
        <span class="icon">
          <img
            src="../assets/icons/Arrow-Right.svg"
            class="w-4 h-4 md:w-auto md:h-auto"
            alt=""
        /></span>
      </button> -->
      <button
        @click="calculate"
        :disabled="loading"
        class="btn-inter flex items-center w-fit"
      >
        <span class="btn-content text-[10px] md:text-base">
          {{ loading ? "CALCULATING" : "CALCULATE" }}
        </span>
        <span class="icon">
          <img
            src="../assets/icons/Arrow-Right.svg"
            class="w-4 h-4 md:w-auto md:h-auto"
            alt=""
          />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import DropdownSelectIcon from "@/components/icons/DropdownSelectIcon.vue";
import AccordionBottomArrow from "@/components/icons/AccordionBottomArrow.vue";
import AccordionTopArrow from "@/components/icons/AccordionTopArrow.vue";
// import { toast } from "bulma-toast";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "PricingCalculator",
  components: {
    DropdownSelectIcon,
    AccordionBottomArrow,
    AccordionTopArrow,
  },
  data() {
    return {
      loading: false,
      est: [
        {
          label: "up to 4",
          value: "ONE_TO_FOUR",
        },
        {
          label: "up to 10",
          value: "ONE_TO_TEN",
        },
        {
          label: "10 and Above",
          value: "TEN_ABOVE",
        },
      ],
      termss: [
        {
          label: "Short",
          value: "SHORT",
        },
        {
          label: "Meduim",
          value: "MEDIUM",
        },
        {
          label: "Long",
          value: "LONG",
        },
      ],
      service: [
        {
          label: "DEDICATED",
          value: "DEDICATED",
        },
        {
          label: "NON DEDICATED",
          value: "NON_DEDICATED",
        },
      ],
      workHours: [
        {
          label: "9/5",
          value: "NINE_TO_FIVE",
        },
        {
          label: "24/7",
          value: "TWENTY_FOUR_SEVEN_FIVE",
        },
        {
          label: "24/7/365",
          value: "TWENTY_FOUR_SEVEN_THREE_SIX_FIVE",
        },
      ],
      timeZones: [
        {
          label: "(GMT -12:00) Eniwetok, Kwajalein",
          value: "UT4",
        },
        {
          label: "(GMT -11:00) Midway Island, Samoa",
          value: "UT10",
        },
        {
          label: "(GMT -10:00) Hawaii",
          value: "EST",
        },
        {
          label: "(GMT -9:30) Taiohae",
          value: "AST",
        },
        {
          label: "(GMT -9:00) Alaska",
          value: "CST",
        },
        {
          label: "(GMT -8:00) Pacific Time (US and Canada)",
          value: "PST",
        },
        {
          label: "(GMT -7:00) Mountain Time (US and Canada)",
          value: "UTC11",
        },
        {
          label: "(GMT -6:00) Central Time (US and Canada), Mexico City",
          value: "UTC10",
        },
        {
          label: "(GMT -5:00) Eastern Time (US and Canada), Bogota, Lima",
          value: "EDT",
        },
        {
          label: "(GMT -4:30) Caracas",
          value: "AKST",
        },
        {
          label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
          value: "TIME1",
        },
        {
          label: "(GMT -3:30) Newfoundland",
          value: "TIME2",
        },
        {
          label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
          value: "TIME3",
        },
        {
          label: "(GMT -2:00) Mid-Atlantic",
          value: "TIME4",
        },
        {
          label: "(GMT -1:00) Azores, Cape Verde Islands",
          value: "TIME5",
        },
        {
          label: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
          value: "TIME6",
        },
        {
          label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
          value: "TIME7",
        },
        {
          label: "(GMT +2:00) Kaliningrad, South Africa",
          value: "TIME8",
        },
        {
          label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
          value: "TIME9",
        },
        {
          label: "(GMT +3:30) Tehran",
          value: "TIME10",
        },
        {
          label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
          value: "TIME11",
        },
        {
          label: "(GMT +4:30) Kabul",
          value: "TIME12",
        },
        {
          label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
          value: "TIME13",
        },
        {
          label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
          value: "TIME14",
        },
        {
          label: "(GMT +5:45) Kathmandu, Pokhara",
          value: "TIME15",
        },
        {
          label: "(GMT +6:00) Almaty, Dhaka, Colombo",
          value: "TIME16",
        },
        {
          label: "(GMT +6:30) Yangon, Mandalay",
          value: "TIME17",
        },
        {
          label: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
          value: "TIME18",
        },
        {
          label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
          value: "TIME19",
        },
        {
          label: "(GMT +8:45) Eucla",
          value: "TIME2O",
        },
        {
          label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
          value: "TIME21",
        },
        {
          label: "(GMT +9:30) Adelaide, Darwin",
          value: "TIME22",
        },
        {
          label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
          value: "TIME23",
        },
        {
          label: "(GMT +10:30) Lord Howe Island",
          value: "TIME24",
        },
        {
          label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
          value: "TIME25",
        },
      ],
      mainServices: [
        {
          server: "SAAS",
          options: [
            {
              label: "Virtual Assistant",
              value: "virtualAssistant",
              active: false,
              subs: [
                { label: "Virtual Representative", value: "VIRTUAL_ASSISTANT" },
              ],
            },
            {
              label: "Phone Support",
              value: "phoneSupport",
              active: false,
              subs: [{ label: "Inbound and Outbound", value: "BOTH" }],
            },
            {
              label: "Email/Chat Support",
              value: "emailandchatSupport",
              active: false,
              subs: [
                { id: "1", label: "less 500", value: "LESS_THEN_500" },
                { id: "2", label: "500 - 1000", value: "BETWEEN_500_TO_1000" },
                { id: "3", label: "above 1000", value: "ABOVE_1000" },
              ],
            },
            {
              label: "IT Support",
              value: "ITsupport",
              active: false,
              subs: [
                {
                  label: "Tier 1",
                  value: "TIER1",
                  hint: "Basic level support that involves",
                },
                {
                  label: "Tier 2",
                  value: "TIER2",
                  hint: "Basic level support that involves",
                },
                {
                  label: "Tier 3",
                  value: "TIER3",
                  hint: "Basic level support that involves",
                },
              ],
            },
          ],
        },
        {
          server: "QAAAS",
          options: [
            {
              label: "Quality Assurance",
              value: "qualityAssurance",
              active: false,
              subs: [
                { label: "0 - 10", value: "ZERO_TEN" },
                { label: "10 and above", value: "TEN_ABOVE" },
              ],
            },
            {
              label: "Data Entry",
              value: "dataEntry",
              active: false,
              subs: [{ label: "Data work", value: "PRICE" }],
            },
            {
              label: "Data Generation & Management",
              value: "dataGenMan",
              active: false,
              subs: [
                {
                  label: "Lead Generation",
                  value: "LEAD_GENERATION",
                  hint: "Lawal",
                },
                {
                  label: "Lead Generation and Qualification",
                  value: "LEAD_GENERATION_QUALIFICATION",
                  hint: "Saliou",
                },
              ],
            },
          ],
        },
      ],
      industriez: [
        {
          label: "Accounting",
          value: "Accounting",
        },
        {
          label: "Administration and Office Support",
          value: "Administration_Office_Support",
        },
        {
          label: "Advertising, Arts and Media",
          value: "Advertising_Arts_Media",
        },
        {
          label: "Banking and Financial Services",
          value: "Banking_Financial_Services",
        },
        {
          label: "Call Centre and Customer Service",
          value: "Call_Centre_Customer_Service",
        },
        {
          label: "Community Services and Development",
          value: "Community_Services_Development",
        },
        {
          label: "Construction",
          value: "Construction",
        },
        {
          label: "Consulting and Strategy",
          value: "Consulting_Strategy",
        },
        {
          label: "Design and Architecture",
          value: "Design_Architecture",
        },
        {
          label: "Education and Training",
          value: "Education_Training",
        },
        {
          label: "Engineering",
          value: "Engineering",
        },
        {
          label: "Farming, Animals and Conservation",
          value: "Farming_Animals_Conservation",
        },
        {
          label: "Government and Defence",
          value: "Government_Defence",
        },
        {
          label: "Healthcare and Medical",
          value: "Healthcare_Medical",
        },
        {
          label: "Hospitality and Tourism",
          value: "Hospitality_Tourism",
        },
        {
          label: "Human Resources and Recruitment",
          value: "Human_Resources_Recruitment",
        },
        {
          label: "Information and Communication Technology",
          value: "Information_Communication_Technology",
        },
        {
          label: "Insurance and Superannuation",
          value: "Insurance_Superannuation",
        },
        {
          label: "Legal",
          value: "Legal",
        },
        {
          label: "Manufacturing,Transport and Logistics",
          value: "Manufacturing_Transport_Logistics",
        },
        {
          label: "Marketing and Communications",
          value: "Marketing_Communications",
        },
        {
          label: "Mining,Resources and Energy",
          value: "Mining_Resources_Energy",
        },
        {
          label: "Real Estate and Property",
          value: "Real_Estate_Property",
        },
        {
          label: "Retail and Consumer Products",
          value: "Retail_Consumer_Products",
        },
        {
          label: "Sales",
          value: "Sales",
        },
        {
          label: "Science and Technology",
          value: "Science_Technology",
        },
        {
          label: "Sport and Recreation",
          value: "Sport_Recreation",
        },
        {
          label: "Trades and Services",
          value: "Trades_Services",
        },
      ],
      args: {
        email: "",
        workhours: "",
        timezone: "",
        term: "",
        estagents: "",
        servicetype: "",
        mainservice: {
          SAAS: {
            virtualAssistant: "",
            phoneSupport: "",
            emailandchatSupport: "",
            ITsupport: "",
          },
          QAAAS: {
            qualityAssurance: "",
            dataEntry: "",
            dataGenMan: "", // To be visited LATER
          },
        },
        industries: "",
        estagents: "",
        price: "",
      },
      openTab: 1,
      workHour: 1,
      term: 1,
      typeOfService: 1,
      NumberOfAgents: 1,
      openServices: false,
      openSaas: {
        SAAS: false,
        QAAAS: false,
      },
      openDmaas: false,
      // openQaas: false,
      openPhoneSupport: false,
      openEmailChatSupport: false,
      openItSupport: false,
    };
  },
  methods: {
    getInputTitle(sub) {
      // console.log("the sub", sub);
      return sub.hint;
    },
    daysInMonth(iMonth, iYear) {
      return 32 - new Date(iYear, iMonth, 32).getDate();
    },
    isWeekday(year, month, day) {
      var day = new Date(year, month, day).getDay();
      return day != 0 && day != 6;
    },
    getWeekdaysInMonth(month, year) {
      var days = this.daysInMonth(month, year);
      var weekdays = 0;
      for (var i = 0; i < days; i++) {
        if (this.isWeekday(year, month, i + 1)) weekdays++;
      }
      return weekdays;
    },
    async calculate() {
      this.loading = true;
      // console.log(this.args, "happy");
      let date = new Date();
      const currentMonth = date.getMonth();
      const currentYear = date.getFullYear();
      const workingDays = this.getWeekdaysInMonth(currentMonth, currentYear);
      const obj = {
        NINE_TO_FIVE: 9.375,
        TWENTY_FOUR_SEVEN_FIVE: 9.375,
        TWENTY_FOUR_SEVEN_THREE_SIX_FIVE: 9.375,
      };
      const add = {
        DEDICATED: {
          virtualAssistant: {
            VIRTUAL_ASSISTANT: 9.375,
          },
          phoneSupport: {
            BOTH: 9.375,
          },
          emailandchatSupport: {
            LESS_THEN_500: 6.25,
            BETWEEN_500_TO_1000: 6.25,
            ABOVE_1000: 6.25,
          },
          ITsupport: {
            TIER1: 9.375,
            TIER2: 12.5,
            TIER3: 18.25,
          },
          qualityAssurance: {
            ZERO_TEN: 9,
            TEN_ABOVE: 6,
          },
          dataEntry: {
            PRICE: 6,
          },
          dataGenMan: {
            LEAD_GENERATION: 0 & "Support will contact you soon",
            LEAD_GENERATION_QUALIFICATION: 0 & "Support will contact you soon",
          },
        },
        NON_DEDICATED: {
          virtualAssistant: {
            VIRTUAL_ASSISTANT: 7.375,
          },
          phoneSupport: {
            BOTH: 7.375,
          },
          emailandchatSupport: {
            LESS_THEN_500: 5,
            BETWEEN_500_TO_1000: 5,
            ABOVE_1000: 5,
          },
          ITsupport: {
            TIER1: 7.375,
            TIER2: 10,
            TIER3: 15,
          },
          qualityAssurance: {
            ZERO_TEN: 9,
            TEN_ABOVE: 6,
          },
          dataEntry: {
            PRICE: 6,
          },
          dataGenMan: {
            LEAD_GENERATION: 0 & "Support will contact you soon",
            // "...,You will be contacted for your request on lead generation  ",
            LEAD_GENERATION_QUALIFICATION:
              // "...,You will be contacted for your request on lead generation and qualification ",
              0 & "Support will contact you soon",
          },
        },
      };
      // if (this.args?.mainservice?.SAAS) {
      const criteria = this.args.servicetype;
      // console.log(this.args.servicetype, this.args.mainservice, add);
      // console.log("criteria:", criteria);
      // console.log("args.mainservice:", this.args.mainservice);
      // console.log("add:", add);
      const virtualAssistantPrice =
        add[criteria]?.virtualAssistant[
          this.args?.mainservice?.SAAS?.virtualAssistant
        ] ?? 0;
      const phoneSupportPrice =
        add[criteria]?.phoneSupport[
          this.args?.mainservice?.SAAS?.phoneSupport
        ] ?? 0;
      const emailandchatSupportPrice =
        add[criteria]?.emailandchatSupport[
          this.args?.mainservice?.SAAS?.emailandchatSupport
        ] ?? 0;
      const ITsupportPrice =
        add[criteria]?.ITsupport[this.args?.mainservice?.SAAS?.ITsupport] ?? 0;
      const qualityAssurancePrice =
        add[criteria]?.qualityAssurance[
          this.args?.mainservice?.QAAAS?.qualityAssurance
        ] ?? 0;
      const dataEntryPrice =
        add[criteria]?.dataEntry[this.args?.mainservice?.QAAAS?.dataEntry] ?? 0;
      const dataGenManPrice =
        add[criteria]?.dataGenMan[this.args?.mainservice?.QAAAS?.dataGenMan] ??
        0;

      const estNumberOfWorkingDays = workingDays * 8;
      // console.log("estNumberOfWorkingDays:", estNumberOfWorkingDays);
      const workHourPrice =
        virtualAssistantPrice * estNumberOfWorkingDays +
        phoneSupportPrice * estNumberOfWorkingDays +
        emailandchatSupportPrice * estNumberOfWorkingDays +
        ITsupportPrice * estNumberOfWorkingDays +
        qualityAssurancePrice * estNumberOfWorkingDays +
        dataEntryPrice * estNumberOfWorkingDays +
        dataGenManPrice * estNumberOfWorkingDays;
      // console.log("workHourPrice:", workHourPrice);
      if (isNaN(workHourPrice)) {
        // console.log("One of the prices is not a number!");
      }
      function removeEmptyValues(obj) {
        Object.keys(obj).forEach((key) => {
          if (obj[key] && typeof obj[key] === "object") {
            removeEmptyValues(obj[key]);
          } else if (
            obj[key] === null ||
            obj[key] === undefined ||
            obj[key] === ""
          ) {
            delete obj[key];
          }
        });
        return obj;
      }
      // const totalPrice = estNumberOfWorkingDays * workHourPrice;
      // console.log(workHourPrice, "happppppyyyyyyyyyy");
      this.args.price = workHourPrice;
      const cleanedArgs = removeEmptyValues(this.args);
      // console.log("cleanedArgs:", cleanedArgs);
      let res;
      res = await this.$store.dispatch("mutate", {
        endpoint: "CreateAllSchema",
        data: { input: cleanedArgs },
      });
      this.loading = false;
      toast.success("Request has been recieved, Kindly check your mailbox", {
        autoClose: 3000,
      });

      // toast({
      //   message: "Request has been recieved, Kindly check your mailbox",
      //   type: "is-success",
      //   dismissible: true,
      //   pauseOnHover: true,
      //   duration: 2000,
      //   position: "top-right",
      //   opacity: 1,
      // });
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
    chooseWorkHours: function (tabNumber) {
      this.args.workhours = tabNumber;
    },
    chooseTerms: function (tabNumber) {
      this.args.term = tabNumber;
    },
    serviceType: function (type) {
      this.args.servicetype = type;
    },
    chooseNumberOfAgents: function (tabNumber) {
      this.args.estagents = tabNumber;
    },
  },
};
</script>
<style scoped>
.checkbox {
  position: relative;
  overflow: hidden;
}
.checkbox__input {
  position: absolute;
  top: -100px;
  left: -200px;
}
.checkbox__inner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 35%;
  border: 1px solid #cfd3d4;
  background: transparent no-repeat center;
}
.checkbox__input:checked + .checkbox__inner {
  border-color: #d1643a;
  background-color: #d1643a;
  background-image: url("../assets/icons/fi_check.svg");
  background-size: 54px 10px;
}

.btn-inter {
  width: 210px;
  background-color: #d1643a;
  overflow: hidden;
  padding: 1.3rem 2rem;
  font-weight: 900;
  position: relative;
}
.btn-inter .btn-content {
  color: #fff;
  padding: 0 40px 0 0;
  position: relative;
  right: 0;
  transition: right 300ms ease;
}
.btn-inter .icon {
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transition: all 300ms ease;
  transform: translateY(-50%);
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 496px) {
  .btn-inter {
    width: 120px;
    padding: 0.8rem;
  }
  .btn-inter .icon {
    width: 55px;
  }
}
</style>
