<template>
  <div class="mb-10">
    <Navbar />
    <PricingCalculator />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import PricingCalculator from "@/components/PricingCalculator.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "PricingView",
  components: {
    Navbar,
    PricingCalculator,
    Footer,
  },
};
</script>
