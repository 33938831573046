<template>
  <div>
    <div class="container mx-auto my-20">
      <div class="flex flex-row items-center space-x-4 mb-10">
        <div
          class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
        ></div>
        <h3 class="text-lg font-medium tracking-[3px]">ABOUT US</h3>
      </div>
      <div
        class="flex flex-col md:space-y-0 md:flex-row items-start md:space-x-10 mb-6"
      >
        <div class="w-full md:w-7/12">
          <p
            class="font-bold text-2xl md:text-4xl md:tracking-[4px] md:leading-[3rem] mb-4 md:mb-10"
          >
            Bespoke call center, customer support and back office teams to grow
            your business.
          </p>
        </div>
        <div class="w-full md:w-5/12">
          <p class="max-w-md">
            We support businesses by helping to keep their processes running at
            the optimum level required to achieve any targeted level of
            effectiveness, efficiency, and productivity.
          </p>
        </div>
      </div>
      <div class="flex flex-row items-center space-x-4">
        <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
        <BookACallButton />
      </div>
    </div>
    <div class="relative">
      <img
        src="../assets/images/about-us-header-img.png"
        class="w-full object-cover"
        alt=""
      />
      <img
        src="../assets/icons/about-us-header-fixed-icon.svg"
        class="absolute bottom-0 right-0 h-20 md:h-fit"
        alt=""
      />
    </div>
    <div
      class="container flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-10 my-20"
    >
      <div class="w-full md:w-1/2">
        <p
          class="max-w-[200px] md:max-w-sm font-bold text-3xl md:text-5xl md:tracking-[4px] md:leading-[4rem] mb-8 md:mb-16"
        >
          Together we are strong
        </p>
      </div>
      <div class="w-full md:w-1/2 space-y-5">
        <p class="text-xl md:text-2xl max-w-md font-bold">
          Our services range from Project Management consulting, phone, email
          and chat solutions, Healthcare staffing and support services, and
          Revenue cycle management.
        </p>
        <p class="text-sm md:text-base max-w-md">
          We recognise that every business is unique. Our customer centric
          approach consistently has us seeking to apply adapted creative
          solutions, tailored specifically to meet the needs and concerns of our
          clients.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";
import BookACallButton from "@/components/BookACallButton.vue";
export default {
  name: "AboutViewHeader",
  components: {
    GetAQuoteButton,
    BookACallButton,
  },
};
</script>
