<template>
  <div class="bg-DarkBlueGrey sticky top-0 z-50 py-8">
    <nav class="container mx-auto text-white">
      <div class="flex flex-row justify-between items-center">
        <router-link to="/">
          <LoremsupportLogoIcon />
        </router-link>
        <div
          class="hidden lg:flex flex-row items-center md:space-x-6 xl:space-x-10"
        >
          <router-link
            v-if="aboutActiveRoute"
            class="hover:border-b-2 border-white"
            to="/about-us"
            :class="[
              $route.path.includes('/about-us') &&
                'font-bold border-b-2 border-white',
            ]"
            >About us</router-link
          >
          <router-link
            v-if="solutionActiveRoute"
            to=""
            :class="[
              $route.path.includes('/front-office-solution')
                ? 'font-bold border-b-2 border-white'
                : $route.path.includes('/back-office-solution')
                ? 'font-bold border-b-2 border-white'
                : $route.path.includes('/health-care-solution')
                ? 'font-bold border-b-2 border-white'
                : $route.path.includes('/logistics-and-ware-housing-solution')
                ? 'font-bold border-b-2 border-white'
                : '',
            ]"
          >
            <div class="group inline-block">
              <button class="outline-none focus:outline-none flex items-center">
                <span class="pr-1 font-semibold flex-1">Solution</span>
                <span>
                  <svg
                    class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </span>
              </button>
              <ul
                class="bg-white shadow-2xl rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 space-y-2 p-3 ease-in-out origin-top z-30"
              >
                <li class="text-black rounded-sm px-3 py-1 hover:bg-blue-100">
                  <router-link to="/front-office-solution">
                    Front Office Solutions</router-link
                  >
                </li>
                <li class="text-black rounded-sm px-3 py-1 hover:bg-blue-100">
                  <router-link to="/back-office-solution"
                    >Back Office Solutions</router-link
                  >
                </li>
                <li class="text-black rounded-sm px-3 py-1 hover:bg-blue-100">
                  <router-link to="/health-care-solution">
                    Health Care / Telemedicine BPO</router-link
                  >
                </li>
                <li class="text-black rounded-sm px-3 py-1 hover:bg-blue-100">
                  <router-link to="/logistics-and-ware-housing-solution"
                    >Logistics and Warehousing</router-link
                  >
                </li>
              </ul>
            </div>
          </router-link>
          <router-link
            v-if="contactActiveRoute"
            class="hover:border-b-2 border-white"
            to="/contact-us"
            :class="[
              $route.path.includes('/contact-us') &&
                'font-bold border-b-2 border-white',
            ]"
            >Contacts</router-link
          >
          <router-link
            v-if="pricingActiveRoute"
            class="hover:border-b-2 border-white"
            to="/pricing"
            :class="[
              $route.path.includes('/pricing') &&
                'font-bold border-b-2 border-white',
            ]"
            >Pricing</router-link
          >
        </div>
        <div class="hidden lg:flex flex-row items-center space-x-8">
          <a
            href="./Lorem-Support-Brochure.pdf"
            target="_blank"
            class="bg-Terracotta text-white text-md uppercase px-5 py-4 hover:opacity-90"
          >
            download brochure
          </a>
        </div>
        <div class="flex flex-row space-x-4 lg:hidden">
          <div
            class="flex flex-col space-y-2 justify-center items-center cursor-pointer w-fit transition-all duration-500"
            @click="openMobileNavbar = !openMobileNavbar"
          >
            <span class="w-8 h-[2px] bg-white"></span>
            <span class="w-8 h-[2px] bg-white"></span>
            <span class="w-8 h-[2px] bg-white"></span>
          </div>
        </div>
      </div>
    </nav>
    <div
      class="block lg:hidden"
      :class="openMobileNavbar ? 'd-block' : 'hidden'"
    >
      <div
        class="flex flex-col space-y-3 items-center transition-all duration-500"
      >
        <div class="flex flex-col space-y-3 items-center justify-center">
          <router-link
            v-if="aboutActiveRoute"
            to="/about-us"
            class="text-white"
            :class="[
              $route.path.includes('/about-us') &&
                'font-bold border-b-2 border-white',
            ]"
            >About us</router-link
          >
          <router-link
            v-if="solutionActiveRoute"
            to="/front-office-solution"
            class="text-white"
            :class="[
              $route.path.includes('/front-office-solution')
                ? 'font-bold border-b-2 border-white'
                : $route.path.includes('/back-office-solution')
                ? 'font-bold border-b-2 border-white'
                : $route.path.includes('/health-care-solution')
                ? 'font-bold border-b-2 border-white'
                : $route.path.includes('/logistics-and-ware-housing-solution')
                ? 'font-bold border-b-2 border-white'
                : '',
            ]"
          >
            <div class="group inline-block">
              <button class="outline-none focus:outline-none flex items-center">
                <span class="pr-1 font-semibold flex-1">Solution</span>
                <span>
                  <svg
                    class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </span>
              </button>
              <ul
                class="bg-white shadow-2xl rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 space-y-2 p-3 ease-in-out origin-top"
              >
                <li class="text-black rounded-sm px-3 py-1 hover:bg-blue-100">
                  <router-link to="/front-office-solution">
                    Front Office Solutions</router-link
                  >
                </li>
                <li class="text-black rounded-sm px-3 py-1 hover:bg-blue-100">
                  <router-link to="/back-office-solution"
                    >Back Office Solutions</router-link
                  >
                </li>
                <li class="text-black rounded-sm px-3 py-1 hover:bg-blue-100">
                  <router-link to="/health-care-solution">
                    Health Care / Telemedicine BPO</router-link
                  >
                </li>
                <li class="text-black rounded-sm px-3 py-1 hover:bg-blue-100">
                  <router-link to="/logistics-and-ware-housing-solution"
                    >Logistics and Warehousing</router-link
                  >
                </li>
              </ul>
            </div>
          </router-link>
          <router-link
            v-if="contactActiveRoute"
            to="/contact-us"
            class="text-white"
            :class="[
              $route.path.includes('/contact-us') &&
                'font-bold border-b-2 border-white',
            ]"
            >Contacts</router-link
          >
          <router-link
            v-if="pricingActiveRoute"
            to="/pricing"
            class="text-white"
            :class="[
              $route.path.includes('/pricing') &&
                'font-bold border-b-2 border-white',
            ]"
            >Pricing</router-link
          >
          <a
            href="./Lorem-Support-Brochure.pdf"
            target="_blank"
            class="flex items-center bg-Terracotta text-white text-[10px] uppercase px-3 py-1.5"
          >
            download brochure
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/runtime-core";
import LoremsupportLogoIcon from "@/components/icons/LoremsupportLogoIcon.vue";

export default defineComponent({
  name: "Navbar",
  components: {
    LoremsupportLogoIcon,
  },
  setup() {
    const openMobileNavbar = ref(false);
    const aboutActiveRoute = () => {
      return $$route.path === "/about-us";
    };
    const solutionActiveRoute = () => {
      if (
        $route.path === "/front-office-solution" ||
        $route.path === "/back-office-solution" ||
        $route.path === "/health-care-solution" ||
        $route.path === "/logistics-and-ware-housing-solution"
      ) {
        return true;
      } else return false;
    };
    const contactActiveRoute = () => {
      return $route.path === "/contact-us";
    };
    const pricingActiveRoute = () => {
      return $route.path === "/pricing";
    };

    return {
      aboutActiveRoute,
      solutionActiveRoute,
      contactActiveRoute,
      pricingActiveRoute,
      openMobileNavbar,
    };
  },
});
</script>

<style scoped>
li > ul {
  transform: translatex(100%) scale(0);
}
li:hover > ul {
  transform: translatex(101%) scale(1);
}
li > button svg {
  transform: rotate(-90deg);
}
li:hover > button svg {
  transform: rotate(-270deg);
}

.group:hover .group-hover\:scale-100 {
  transform: scale(1);
}
.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg);
}
.scale-0 {
  transform: scale(0);
}
.min-w-32 {
  min-width: 8rem;
}
</style>
