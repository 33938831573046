<template>
  <div class="relative bg-FloralWhite">
    <img
      src="../assets/icons/our-specialist-left-border.svg"
      class="hidden md:block absolute top-[50%] -translate-y-2/4 -translate-x-2/4"
      alt=""
    />
    <div
      class="container max-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row md:space-x-10 py-24"
    >
      <div class="w-full md:w-1/2 flex justify-center">
        <img
          src="../assets/images/backend-solution-specialist-img.png"
          class="object-cover"
          alt=""
        />
      </div>
      <div class="w-full md:w-1/2 space-y-8 flex flex-col">
        <p
          class="font-bold text-xl md:text-3xl leading-[2rem] md:leading-[3rem] max-w-lg"
        >
          Our team of dedicated experienced specialists to do more than just
          manage your back office business processes.
        </p>
        <p class="text-md max-w-lg">
          Back office operations are essential to every company striving to
          achieve more in terms of productivity and cost efficiency.
        </p>
        <p class="text-md max-w-lg">
          While customers rarely see back office representatives, what happens
          there can have an impact on their satisfaction. Effectively managing
          back office support is essential for a meaningful customer experience.
        </p>
        <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";

export default {
  name: "BackendOfficeSolutionSpecialist",
  components: {
    GetAQuoteButton,
  },
};
</script>
