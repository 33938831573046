<template>
  <div>
    <div
      class="container mx-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row my-20 md:space-x-10"
    >
      <div class="w-full md:w-1/2">
        <div class="flex flex-row items-center space-x-4 mb-10">
          <div
            class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
          ></div>
          <h3 class="text-md md:text-lg font-medium tracking-[3px]">
            BACK OFFICE SOLUTIONS
          </h3>
        </div>
        <div class="block md:hidden mb-4">
          <img
            src="../assets/images/BackendOfficeSolutionViewHeader-img.png"
            class="object-cover"
            alt=""
          />
        </div>
        <p
          class="font-bold text-2xl md:text-4xl md:leading-[3rem] mb-4 md:mb-10"
        >
          Scale down on resources spent on staffing and training, while
          utilizing our team of dedicated professionals to provide a top-notch
          experience to your customers.
        </p>
        <div class="flex flex-row items-center space-x-4">
          <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
          <BookACallButton />
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div class="hidden md:block">
          <img
            src="../assets/images/BackendOfficeSolutionViewHeader-img.png"
            class="object-cover"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";
import BookACallButton from "@/components/BookACallButton.vue";
export default {
  name: "BackendOfficeSolutionViewHeader",
  components: {
    GetAQuoteButton,
    BookACallButton,
  },
};
</script>
