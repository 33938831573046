<template>
  <router-link
    to="/pricing"
    class="btn-inter flex items-center w-fit"
    :style="{
      background: color,
    }"
  >
    <span class="btn-content text-[10px] md:text-base">{{ text }}</span>
    <span class="icon">
      <img src="../assets/icons/Arrow-Right.svg" class="w-4 h-4 md:w-auto md:h-auto" alt=""
    /></span>
  </router-link>
</template>

<script>
export default {
  name: "GetAQuoteButton",
  props: ["text", "color"],
};
</script>

<style scoped>
.btn-inter {
  overflow: hidden;
  padding: 1.3rem 2rem;
  font-weight: 900;
  position: relative;
}
.btn-inter .btn-content {
  color: #fff;
  padding: 0 40px 0 0;
  position: relative;
  right: 0;
  transition: right 300ms ease;
}
.btn-inter .icon {
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transition: all 300ms ease;
  transform: translateY(-50%);
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-inter:hover .btn-content {
  right: 100%;
}
.btn-inter:hover .icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 496px) {
  .btn-inter {
    padding: 0.8rem;
  }
  .btn-inter .icon {
    width: 55px;
  }
}
</style>
