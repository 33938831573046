<template>
  <div class="mb-10">
    <Navbar />
    <SolutionViewHeader />
    <OurSpecialistSolution />
    <PhoneSupport />
    <EmailSupport />
    <ChatServices />
    <WeMakeYourNoneCore />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import SolutionViewHeader from "@/components/SolutionViewHeader.vue";
import OurSpecialistSolution from "@/components/OurSpecialistSolution.vue";
import PhoneSupport from "@/components/PhoneSupport.vue";
import EmailSupport from "@/components/EmailSupport.vue";
import ChatServices from "@/components/ChatServices.vue";
import WeMakeYourNoneCore from "@/components/WeMakeYourNoneCore.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "SolutionView",
  components: {
    Navbar,
    SolutionViewHeader,
    OurSpecialistSolution,
    PhoneSupport,
    EmailSupport,
    ChatServices,
    WeMakeYourNoneCore,
    Footer,
  },
};
</script>
