<template>
  <div class="relative bg-ClearDay">
    <img
      src="../assets/icons/GainAccess-icon.svg"
      class="hidden md:block absolute top-[50%] -translate-y-2/4 -translate-x-2/4"
      alt=""
    />
    <div
      class="container max-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row md:space-x-10 py-24"
    >
      <div class="w-full md:w-1/2 flex justify-center">
        <img src="../assets/images/doctor_2.png" class="object-cover" alt="" />
      </div>
      <div class="w-full md:w-1/2 space-y-8 flex flex-col">
        <p
          class="font-bold text-xl md:text-3xl leading-[2rem] md:leading-[3rem] max-w-lg"
        >
          Gain access to a combination of value, knowledge, and accumulated
          experience.
        </p>
        <p class="text-md max-w-lg">
          Through technology, healthcare providers can now initiate a patient
          relationship much earlier (prior to the onset of disease or chronic
          condition) and monitor follow-up treatment much later (ongoing
          oversight of regimen compliance and associated facilities management).
        </p>
        <p class="text-md max-w-lg">
          A pioneer in exceptional customer care and support, Lorem Support is a
          leading provider of multichannel demand generation and customer
          engagement services for Global 2000 companies.
        </p>
        <GetAQuoteButton text="GET A QUOTE" color="#3AADD1" />
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";

export default {
  name: "GainAccess",
  components: {
    GetAQuoteButton,
  },
};
</script>
