<template>
  <div class="container mx-auto">
    <div
      class="flex flex-col-reverse space-y-10 md:space-y-0 md:flex-row md:items-start md:space-x-16 mb-16"
    >
      <div class="w-full md:w-1/2">
        <div class="hidden md:block">
          <img
            src="../assets/images/vision-and-mission-img.png"
            class="w-full object-cover"
            alt=""
          />
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div class="flex flex-row items-center space-x-5 mb-10">
          <div
            class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
          ></div>
          <h3 class="text-lg font-medium">OUR VISION AND MISSION</h3>
        </div>
        <div class="block md:hidden mb-4">
          <img
            src="../assets/images/vision-and-mission-img.png"
            class="w-full object-cover"
            alt=""
          />
        </div>
        <p
          class="font-bold text-2xl md:text-4xl md:tracking-[3px] md:leading-[3rem] mb-8 md:mb-16"
        >
          Help our customers with our services, by providing innovative business
          solutions that increase efficiency and productivity.
        </p>
        <div class="space-y-8">
          <p>
            Our Mission is to provide innovative business solutions that improve
            efficiency and Motivates Result.
          </p>
          <router-link to="/about-us" class="btn-inter flex items-center w-fit">
            <span class="btn-content">ABOUT US</span>
            <span class="icon">
              <img src="../assets/icons/Arrow-Right-with-blue.svg" alt=""
            /></span>
          </router-link>
        </div>
      </div>
    </div>

    <!-- counter -->
    <div
      class="flex flex-col space-y-5 md:space-y-0 items-center md:flex-row md:justify-between mb-28"
    >
      <div class="flex items-center space-x-3">
        <p class="text-3xl md:text-3xl xl:text-6xl text-DarkBlueGrey font-bold">
          50 +
        </p>
        <div class="flex flex-col">
          <span>Clients & Partners</span>
          <span>Trusted</span>
        </div>
      </div>
      <img
        src="../assets/icons/counter-separator-line.svg"
        class="hidden md:block"
        alt=""
      />
      <hr class="w-24 md:hidden" />
      <div class="flex items-center space-x-3">
        <p class="text-3xl md:text-3xl xl:text-6xl text-DarkBlueGrey font-bold">
          99 %
        </p>
        <div class="flex flex-col">
          <span>Success</span>
          <span>Rate</span>
        </div>
      </div>
      <img
        src="../assets/icons/counter-separator-line.svg"
        class="hidden md:block"
        alt=""
      />
      <hr class="w-24 md:hidden" />
      <div class="flex items-center space-x-3">
        <p class="text-3xl md:text-3xl xl:text-6xl text-DarkBlueGrey font-bold">
          80 +
        </p>
        <div class="flex flex-col">
          <span>Projects Completed</span>
          <span>on 12 countries</span>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "VisionAndMission",
};
</script>

<style scoped>
.btn-inter {
  background-color: #d1643a;
  overflow: hidden;
  padding: 1.3rem 2rem;
  font-weight: 900;
  position: relative;
}
.btn-inter .btn-content {
  color: #fff;
  padding: 0 40px 0 0;
  position: relative;
  right: 0;
  transition: right 300ms ease;
  font-size: 0.875rem;
}
.btn-inter .icon {
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transition: all 300ms ease;
  transform: translateY(-50%);
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-inter:hover .btn-content {
  right: 100%;
}
.btn-inter:hover .icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 496px) {
  .btn-inter {
    padding: 1rem 1.75rem;
  }
}
</style>
