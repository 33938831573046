<template>
  <div class="bg-FloralWhite">
    <div
      class="container max-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row md:space-x-10 py-24"
    >
      <div class="w-full md:w-1/2 flex justify-center mt-10">
        <img
          src="../assets/images/email-support-img.png"
          class="object-cover"
          alt=""
        />
      </div>
      <div class="w-full md:w-1/2 space-y-5 flex flex-col mb-6">
        <p class="font-bold text-3xl max-w-lg">Email Support</p>
        <p class="text-md max-w-lg leading-[2rem]">
          Leverage our experienced workforce and reliable round the clock email
          support service to increase your customer satisfaction and make sure
          none of the mails go unanswered
        </p>
        <div class="flex flex-col space-y-4 text-MistBlue">
          <div class="flex flex-row items-center space-x-2">
            <img src="../assets/icons/support-icon.svg" alt="" />
            <p>Scheduled Send and Snooze</p>
          </div>
          <div class="flex flex-row items-center space-x-2">
            <img src="../assets/icons/support-icon.svg" alt="" />
            <p>Handle warm leads & customer care calls</p>
          </div>
          <div class="flex flex-row items-center space-x-2">
            <img src="../assets/icons/support-icon.svg" alt="" />
            <p>Handle all inbound & outbound business calls</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailSupport",
};
</script>
