<template>
  <div class="my-16 md:my-32">
    <div class="flex flex-row items-center justify-center space-x-5 mb-10">
      <div
        class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
      ></div>
      <h3 class="text-sm md:text-lg font-medium">
        PARTNERED WITH MORE THAN 40 BRANDS
      </h3>
    </div>

    <div
      class="flex flex-row w-full sm:w-full overflow-auto scrollbar-hide -space-x-4 md:-space-x-10 relative"
    >
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/kicthens.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/clutality.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/lincoln-diagnotics-llc.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/securranty.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/orcanos.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/social-proof.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/kicthens.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/clutality.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/lincoln-diagnotics-llc.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/securranty.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/orcanos.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/social-proof.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/kicthens.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/clutality.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/lincoln-diagnotics-llc.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/securranty.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/orcanos.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
      <div
        class="slide flex flex-row items-center justify-center space-x-5 border rounded-2xl py-2 px-4"
      >
        <span class="text-sm">
          <img
            src="../assets/icons/social-proof.svg"
            class="w-10 h-10 md:w-24 md:h-24 xl:w-40 xl:h-40"
            alt=""
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partners",
};
</script>

<style scoped>
.slide {
  border-radius: 50%;
  animation: scroll 60s linear infinite;
}
.slide span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14rem !important;
  height: 14rem !important;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-1000%);
  }
}

@media screen and (max-width: 496px) {
  .slide span {
    width: 5rem !important;
    height: 5rem !important;
  }
}
</style>
