<template>
  <div>
    <div
      class="container mx-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row my-20 md:space-x-10"
    >
      <div class="w-full md:w-1/2">
        <div class="flex flex-row items-center space-x-4 mb-10">
          <div
            class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
          ></div>
          <h3 class="text-md md:text-lg font-medium tracking-[3px]">
            HEALTH CARE/TELEMEDICINE BPO
          </h3>
        </div>
        <div class="block md:hidden mb-4">
          <img
            src="../assets/images/doctor_1.png"
            class="object-cover"
            alt=""
          />
        </div>
        <p class="font-bold text-2xl md:text-4xl md:leading-[3rem] mb-16">
          Health is wealth. There’s nothing more important than our health.
        </p>
        <p class="text-xl max-w-lg mb-4 md:mb-10">
          We help your organization optimize the lifetime value of customer
          relationships by building an intelligent customer experience that
          attracts, converts, engages and retains customers.
        </p>
        <div class="flex flex-row items-center space-x-4">
          <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
          <BookACallButton />
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div class="hidden md:block">
          <img
            src="../assets/images/doctor_1.png"
            class="object-cover"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";
import BookACallButton from "@/components/BookACallButton.vue";
export default {
  name: "HealthCareSolutionViewHeader",
  components: {
    GetAQuoteButton,
    BookACallButton,
  },
};
</script>
