<template>
  <div class="mb-10">
    <Navbar />
    <BackendOfficeSolutionViewHeader />
    <BackendOfficeSolutionSpecialist />
    <BackOfficeServices />
    <BackendOfficeSolutionEfficient />
    <MarcketResearch />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import BackendOfficeSolutionViewHeader from "@/components/BackendOfficeSolutionViewHeader.vue";
import BackendOfficeSolutionSpecialist from "@/components/BackendOfficeSolutionSpecialist.vue";
import BackOfficeServices from "@/components/BackOfficeServices.vue";
import BackendOfficeSolutionEfficient from "@/components/BackendOfficeSolutionEfficient.vue";
import MarcketResearch from "@/components/MarcketResearch.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "BackendOfficeSolutionView",
  components: {
    Navbar,
    BackendOfficeSolutionViewHeader,
    BackendOfficeSolutionSpecialist,
    BackOfficeServices,
    BackendOfficeSolutionEfficient,
    MarcketResearch,
    Footer,
  },
};
</script>
