import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import SolutionView from "../views/SolutionView.vue";
import BackendOfficeSolutionView from "../views/BackendOfficeSolutionView.vue";
import HealthCareSolutionView from "../views/HealthCareSolutionView.vue";
import LogisticsAndWareHousingView from "../views/LogisticsAndWareHousingView.vue";
import ContactView from "../views/ContactView.vue";
import PricingView from "../views/PricingView.vue";
import CalendlyView from "../views/CalendlyView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/front-office-solution",
    name: "front-office-solution",
    component: SolutionView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/back-office-solution",
    name: "back-office-solution",
    component: BackendOfficeSolutionView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/health-care-solution",
    name: "health-care-solution",
    component: HealthCareSolutionView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/logistics-and-ware-housing-solution",
    name: "logistics-and-ware-housing-solution",
    component: LogisticsAndWareHousingView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: PricingView,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/book-a-call",
    name: "book-a-call",
    component: CalendlyView,
    meta: { toTop: true, smoothScroll: true },
  },
  // {
  //   path: "/about-us",
  //   name: "about-us",
  //   component: function () {
  //     return import("../views/AboutView.vue");
  //   },
  //   meta: { toTop: true, smoothScroll: true },
  // },
  // {
  //   path: "/front-office-solution",
  //   name: "front-office-solution",
  //   component: function () {
  //     return import("../views/SolutionView.vue");
  //   },
  //   meta: { toTop: true, smoothScroll: true },
  // },
  // {
  //   path: "/back-office-solution",
  //   name: "back-office-solution",
  //   component: function () {
  //     return import("../views/BackendOfficeSolutionView.vue");
  //   },
  //   meta: { toTop: true, smoothScroll: true },
  // },
  // {
  //   path: "/health-care-solution",
  //   name: "/health-care-solution",
  //   component: function () {
  //     return import("../views/HealthCareSolutionView.vue");
  //   },
  //   meta: { toTop: true, smoothScroll: true },
  // },
  // {
  //   path: "/logistics-and-ware-housing-solution",
  //   name: "/logistics-and-ware-housing-solution",
  //   component: function () {
  //     return import("../views/LogisticsAndWareHousingView.vue");
  //   },
  //   meta: { toTop: true, smoothScroll: true },
  // },
  // {
  //   path: "/contact-us",
  //   name: "/contact-us",
  //   component: function () {
  //     return import("../views/ContactView.vue");
  //   },
  //   meta: { toTop: true, smoothScroll: true },
  // },
  // {
  //   path: "/pricing",
  //   name: "/pricing",
  //   component: function () {
  //     return import("../views/PricingView.vue");
  //   },
  //   meta: { toTop: true, smoothScroll: true },
  // },
  // {
  //   path: "/book-a-call",
  //   name: "/book-a-call",
  //   component: function () {
  //     return import("../views/CalendlyView.vue");
  //   },
  //   meta: { toTop: true, smoothScroll: true },
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {};
    if (to.meta.toTop) scroll.top = 0;
    if (to.meta.smoothScroll) scroll.behavior = "smooth";
    return scroll;
  },
});

export default router;
