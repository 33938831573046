<template>
  <div class="relative bg-ChinaIvory">
    <img
      src="../assets/icons/GainAccess-icon.svg"
      class="hidden md:block absolute top-[50%] -translate-y-2/4 -translate-x-2/4"
      alt=""
    />
    <div
      class="container max-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row md:space-x-10 py-24"
    >
      <div class="w-full md:w-1/2 flex justify-center">
        <img
          src="../assets/images/logistics-gain-access-img.png"
          class="object-cover"
          alt=""
        />
      </div>
      <div class="w-full md:w-1/2 space-y-8 flex flex-col">
        <p
          class="font-bold text-xl md:text-3xl leading-[2rem] md:leading-[3rem] max-w-lg"
        >
          Gain access to a combination of value, knowledge, and accumulated
          experience.
        </p>
        <p class="text-md max-w-lg">
          98% of logistics companies say that customer service is a critical
          piece of their company’s overall business strategy. And 55% said
          keeping up with customer service 24/7 is their biggest challenge –
          2019 Logistics Tech Trends
        </p>
        <p class="text-md max-w-lg">
          When successful market leaders understand their current and future
          customers ‘ expectations and requirements, they build a plan. Whether
          the business is small, medium or big, strategies rely on efficient
          logistics.
        </p>
        <GetAQuoteButton text="GET A QUOTE" color="#3AADD1" />
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";

export default {
  name: "LogisticsAndWareGainAccess",
  components: {
    GetAQuoteButton,
  },
};
</script>
