<template>
  <div>
    <div
      class="container mx-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row my-20 md:space-x-10"
    >
      <div class="w-full md:w-1/2 space-y-8 flex flex-col">
        <div class="flex flex-row items-center space-x-4">
          <div
            class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
          ></div>
          <h3 class="text-md md:text-lg font-medium tracking-[3px]">
            MARKET RESEARCH AND DATA MINING
          </h3>
        </div>
        <div class="block md:hidden mb-4">
          <img
            src="../assets/images/standard-research-img.png"
            class="object-cover"
            alt=""
          />
        </div>
        <p
          class="font-bold text-xl md:text-3xl leading-[2rem] md:leading-[3rem] max-w-lg"
        >
          Standard research tools to provide highly accurate market research
          data
        </p>
        <p class="text-md max-w-lg">
          Lorem Support follows a streamlined process, using standard research
          tools to provide highly accurate market research data, right from
          defining the problem and the objectives to finally collecting and
          generating a report with supporting statistics.
        </p>
        <p class="text-md max-w-lg">
          Our quality management department takes special consideration to
          ensure that the study is accurate and error-free.
        </p>
        <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
      </div>
      <div class="w-full md:w-1/2">
        <div class="hidden md:block">
          <img
            src="../assets/images/standard-research-img.png"
            class="object-cover"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";

export default {
  name: "MarcketResearch",
  components: {
    GetAQuoteButton,
  },
};
</script>
