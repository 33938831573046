<template>
  <div>
    <div class="container max-auto mt-16">
      <p
        class="font-bold text-xl md:text-3xl leading-[2rem] md:leading-[3rem] max-w-4xl mb-2 md:mb-16"
      >
        The efficiency and reliability of your back office ensures the health of
        your business and allows you to to continue to do what you do best –
        Focus on your core competencies.
      </p>
    </div>
    <div class="relative">
      <img
        src="../assets/icons/chat-service-left-icon.svg"
        class="hidden md:block absolute top-[50%] -translate-y-2/4 -translate-x-2/4"
        alt=""
      />
      <div
        class="container max-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row md:space-x-10 py-24"
      >
        <div class="w-full md:w-1/2 flex justify-center">
          <div class="hidden md:block">
            <img
              src="../assets/images/backend-efficient-img.png"
              class="object-cover"
              alt=""
            />
          </div>
        </div>
        <div class="w-full md:w-1/2 space-y-12 flex flex-col">
          <div class="flex flex-row items-center space-x-4">
            <div
              class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
            ></div>
            <h3 class="text-lg font-medium">DATA ENTRY</h3>
          </div>
          <div class="block md:hidden mb-4">
            <img
              src="../assets/images/backend-efficient-img.png"
              class="object-cover"
              alt=""
            />
          </div>
          <p
            class="font-bold text-xl md:text-3xl leading-[2rem] md:leading-[3rem] max-w-lg"
          >
            Turn data into information and information into insight
          </p>
          <p class="text-md max-w-lg">
            Precise data entry is vital to a successful business. This is a rare
            and effective skill that lets businesses keep reliable and up-to –
            date archives on anything from contracts to invoices.
          </p>
          <p class="text-md max-w-lg">
            Every company stands to benefit from a helping hand. Do you need
            help with a big project or are searching for consistent, continuous
            support?
          </p>
          <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";

export default {
  name: "BackendOfficeSolutionEfficient",
  components: {
    GetAQuoteButton,
  },
};
</script>
