<template>
  <div class="bg-DarkBlueGrey text-white">
    <div class="container mx-auto flex flex-row items-center">
      <div class="w-full md:1/2 py-32">
        <div class="w-full md:w-1/2 space-y-6">
          <h3 class="font-bold text-2xl md:text-3xl">Ready to work, send us a mail</h3>
          <div
            class="w-full flex flex-row items-center justify-between md:w-full py-4 pl-10 pr-3 border border-white"
          >
            <div class="flex items-center">
              <img src="../assets/icons/mail_message-icon.svg" alt="" />
              <input
                type="text"
                name=""
                id=""
                class="bg-transparent focus:outline-0 px-3"
                placeholder="Your mail"
              />
            </div>
            <div>
              <img
                src="../assets/icons/mail-arrow_forward.svg"
                alt=""
                class="cursor-pointer"
              />
            </div>
          </div>
          <p class="text-lg">
            We’d love to hear about your company’s challenges, even if you are
            not sure of what your next step is.
          </p>
        </div>
      </div>
      <div class="hidden md:flex justify-center md:1/2">
        <img
          src="../assets/images/send-us-a-mail-img.png"
          class="h-full objet-cover"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SendUsAmail",
};
</script>
