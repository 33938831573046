<template>
  <div>
    <div
      class="relative container mx-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row my-2 md:my-20 md:space-x-36"
    >
      <div class="w-full md:w-1/2">
        <div class="flex flex-row items-center space-x-4 my-10">
          <div
            class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
          ></div>
          <h3 class="text-lg font-medium tracking-[3px]">CONTACT US</h3>
        </div>
        <div class="block md:hidden mb-4">
          <img
            src="../assets/images/contact-header-img.png"
            class="object-cover"
            alt=""
          />
        </div>
        <p
          class="font-bold text-2xl md:text-4xl leading-[2rem] md:leading-[3rem] mb-16"
        >
          You can reach out via chat, calls or email and we'll respond as fast
          as humanly possible.
        </p>
        <div class="text-sm space-y-10 md:text-xl max-w-lg">
          <div class="flex flex-col space-y-2">
            <div>
              <span class="font-axiforma-bold">Ukraine: </span>
              <span class=""
                >UNIQA Park Tower. Velyka Arnauts’ka St, 72/74, Odesa, Odessa
                Oblast, Ukraine 65000</span
              >
            </div>
            <div>
              <span class="font-axiforma-bold">USA: </span
              ><span>125 NW 11th Avenue Dania Beach FL 33004</span>
            </div>
            <div>
              <span class="font-axiforma-bold">USA 2: </span
              ><span>190 Bluegrass Valley Pkwy Alpharetta, GA 30005</span>
            </div>
            <div>
              <span class="font-axiforma-bold">Nigeria: </span
              ><span>FHA Estate, Guzape, Abuja</span>
            </div>
            <div>
              <span class="font-axiforma-bold">Germany: </span
              ><span>Munich DE. 3, Stahlgruberring</span>
            </div>
          </div>
          <div class="space-y-10">
            <div>
              <span class="font-axiforma-bold">E: </span
              ><span>info@loremsupport.com</span>
            </div>
            <div class="flex flex-row items-start space-x-1">
              <span class="font-axiforma-bold">T:</span>
              <div class="flex flex-col space-y-2">
                <div>
                  <span class="font-axiforma-bold">(US): </span>
                  <span>(616) 244-9316</span>
                </div>
                <div>
                  <span class="font-axiforma-bold">(NGN): </span>
                  <span>(+234) 818-887-2426</span>
                </div>
              </div>
            </div>
            <div class="flex flex-row items-center space-x-4">
              <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
              <BookACallButton />
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div class="hidden md:block">
          <img
            src="../assets/images/contact-header-img.png"
            class="object-cover"
            alt=""
          />
        </div>
      </div>
    </div>
    <img
      src="../assets/icons/chat-service-left-icon.svg"
      class="hidden md:block absolute -bottom-72 left-0"
      alt=""
    />
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";
import BookACallButton from "@/components/BookACallButton.vue";
export default {
  name: "ContactHeader",
  components: {
    GetAQuoteButton,
    BookACallButton,
  },
};
</script>
