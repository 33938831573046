<template>
  <svg
    width="11"
    height="6"
    viewBox="0 0 11 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.27199 5.94824L5.46994 2.30367L1.66789 5.94824L0.499939 4.82622L5.46994 0.0516701L10.4399 4.82622L9.27199 5.94824Z"
      fill="#323232"
    />
  </svg>
</template>
