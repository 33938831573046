<template>
  <div class="mb-10">
    <Navbar />
    <AboutViewHeader />
    <!-- <SendUsAmail /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import AboutViewHeader from "@/components/AboutViewHeader.vue";
import SendUsAmail from "@/components/SendUsAmail.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "AboutView",
  components: {
    Navbar,
    AboutViewHeader,
    SendUsAmail,
    Footer,
  },
};
</script>
