<template>
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16795 0.0517578L4.97 3.69633L8.77205 0.0517578L9.94 1.17378L4.97 5.94833L0 1.17378L1.16795 0.0517578Z"
      fill="#323232"
    />
  </svg>
</template>
