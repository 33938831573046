<template>
  <router-view />
  <div
    ref="scrollTopButton"
    class="invisible sticky w-full flex justify-end bottom-10 pb-3 pr-5 lg:pr-16 transition"
  >
    <div
      @click="scrollToTop"
      class="z-20 cursor-pointer text-gray-400 hover:text-white transition flex items-center justify-center bg-DarkBlueGrey w-8 h-8 p-5 rounded-full"
    >
      <button class="text-2xl">&#8911;</button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      const scrollBtn = this.$refs.scrollTopButton;

      if (window.scrollY > 0) {
        scrollBtn.classList.remove("invisible");
      } else {
        scrollBtn.classList.add("invisible");
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
});
</script>
<style></style>
