<template>
  <div class="mb-10">
    <Navbar />
    <div
      class="container mx-auto mt-10 md:mt-16 flex flex-col items-center text-center space-y-4"
    >
      <p class="text-DarkBlueGrey text-xl md:text-3xl">
        You Can Book A 30 Minutes Call
      </p>
      <p class="max-w-xl text-lg md:text-xl">
        Book a conversation with us to discuss your business needs. We will help
        you with the most suitable solution for your company.
      </p>
    </div>
    <div class="container mx-auto my-10">
      <!-- Calendly inline widget begin -->
      <!-- Calendly inline widget begin -->
      <!-- <div class="calendly-inline-widget" data-url="https://calendly.com/lawalsaliouakande/15min" style="min-width:320px;height:630px;"></div>
<script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script> -->

      <!-- Calendly inline widget end -->
      <div
        class="calendly-inline-widget"
        data-url="https://calendly.com/loremsupport/30min"
        style="min-width: 320px; height: 630px"
      ></div>
      <!-- Calendly inline widget end -->
    </div>
    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "CalendlyView",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    let Script = document.createElement("script");
    Script.setAttribute(
      "src",
      // "https://assets.calendly.com/assets/external/widget.js"
      "https://assets.calendly.com/assets/external/widget.js"
    );
    document.head.appendChild(Script);
  },
};
</script>
