<template>
  <div class="mb-10">
    <Navbar />
    <HealthCareSolutionViewHeader />
    <GainAccess />
    <GiveYourCustomer />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import HealthCareSolutionViewHeader from "@/components/HealthCareSolutionViewHeader.vue";
import GainAccess from "@/components/GainAccess.vue";
import GiveYourCustomer from "@/components/GiveYourCustomer.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "HealthCareSolutionView",
  components: {
    Navbar,
    HealthCareSolutionViewHeader,
    GainAccess,
    GiveYourCustomer,
    Footer,
  },
};
</script>
