<template>
  <div class="container max-auto">
    <p class="font-bold text-3xl md:text-5xl leading-[2.5rem] md:leading-[3.5rem] max-w-5xl mt-20 mb-16">
      Give your customers a renewed experience and increase your Business’
      Productivity through:
    </p>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12 mb-44">
      <div class="relative border border-AquaHaze px-5 py-10 space-y-6">
        <img
          src="../assets/icons/sales-icon.svg"
          class="absolute -top-8 w-fit cursor-pointer p-5 bg-Champagne rounded-md"
          alt=""
        />
        <p class="font-bold text-BalticSea">Sales through service</p>
        <p class="text-xs max-w-xs text-MistBlue">
          Deliver exceptional service experiences to customers
        </p>
      </div>
      <div class="relative border border-AquaHaze px-5 py-10 space-y-6">
        <img
          src="../assets/icons/customer-selection-icon.svg"
          class="absolute -top-8 w-fit cursor-pointer p-5 bg-SnowFlurry rounded-md"
          alt=""
        />
        <p class="font-bold text-BalticSea">Customer selection</p>
        <p class="text-xs max-w-xs text-MistBlue">
          Serve your top customers through value capturing.
        </p>
      </div>
      <div class="relative border border-AquaHaze px-5 py-10 space-y-6">
        <img
          src="../assets/icons/customer-acquisition-icon.svg"
          class="absolute -top-8 w-fit cursor-pointer p-5 bg-Ice rounded-md"
          alt=""
        />
        <p class="font-bold text-BalticSea">Customer acquisition</p>
        <p class="text-xs max-w-xs text-MistBlue">
          Get potential customers to buy your products.
        </p>
      </div>
      <div class="relative border border-AquaHaze px-5 py-10 space-y-6">
        <img
          src="../assets/icons/customer-retention.svg"
          class="absolute -top-8 w-fit cursor-pointer p-5 bg-PaleMauve rounded-md"
          alt=""
        />
        <p class="font-bold text-BalticSea">Customer Retention</p>
        <p class="text-xs max-w-xs text-MistBlue">
          Measure customer loyalty over time and gauge overall success.
        </p>
      </div>
    </div>

    <div class="flex justify-center">
      <p class="font-bold text-xl md:text-3xl leading-[2rem] md:leading-[3rem] text-center max-w-4xl mb-16">
        Through these services we help your brand build customer loyalty by
        creating exceptional customer experience that drives growth and
        profitability.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GiveYourCustomer",
};
</script>
