<template>
  <div
    class="container max-auto flex flex-col-reverse items-center space-y-5 md:space-y-0 md:flex-row md:space-x-10 py-24"
  >
    <div class="w-full md:w-1/2 space-y-8 flex flex-col">
      <p
        class="font-bold text-xl md:text-3xl leading-[2rem] md:leading-[3rem] max-w-md"
      >
        Leverage Lorem Support Expertise in logistics and ware-housing
      </p>
      <p class="text-md max-w-lg">
        We provide you with solutions as regards to customer satisfaction and
        Retention through a mix of Experience, timeliness, reliability and
        flexibility.
      </p>
      <GetAQuoteButton text="GET A QUOTE" color="#3AADD1" />
    </div>
    <div class="w-full md:w-1/2 flex justify-center ">
      <img
        src="../assets/images/logistics-leverage-img.png"
        class="object-cover mb-4"
        alt=""
      />
    </div>
  </div>
  <div class="container max-auto">
    <p
      class="font-bold text-3xl md:text-5xl leading-[2.5rem] md:leading-[3.5rem] max-w-5xl mt-8 md:mt-20 mb-16"
    >
      We aim to reduce freight and support inefficiencies by utilizing our
      multichannel services which includes:
    </p>
    <div
      class="flex flex-col space-y-12 md:space-y-0 md:flex-row justify-center md:space-x-36 mb-16 md:mb-44"
    >
      <div class="relative border border-AquaHaze px-5 py-10 space-y-6">
        <img
          src="../assets/icons/call-onbound-icon.svg"
          class="absolute -top-8 w-fit cursor-pointer p-5 bg-Champagne rounded-md"
          alt=""
        />
        <p class="font-bold text-BalticSea">Calls (Inbound, Outbound)</p>
        <p class="text-xs max-w-[150px] text-MistBlue">
          Keep all your interactions in one place
        </p>
      </div>
      <div class="relative border border-AquaHaze px-5 py-10 space-y-6">
        <img
          src="../assets/icons/customer-selection-icon.svg"
          class="absolute -top-8 w-fit cursor-pointer p-5 bg-SnowFlurry rounded-md"
          alt=""
        />
        <p class="font-bold text-BalticSea">Emails and chats</p>
        <p class="text-xs max-w-[230px] text-MistBlue">
          Manage your business communications
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";

export default {
  name: "LeverageLoremSupport",
  components: {
    GetAQuoteButton,
  },
};
</script>
