<template>
  <div class="mb-10">
    <Navbar />
    <ContactHeader />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import ContactHeader from "@/components/ContactHeader.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "ContactView",
  components: {
    Navbar,
    ContactHeader,
    Footer,
  },
};
</script>
