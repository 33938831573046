<template>
  <div class="relative bg-MandysPink py-24 md:py-44 my-24 md:my-44">
    <img
      src="../assets/icons/we-make-icon_1.svg"
      class="h-20 md:h-fit absolute top-0 bottom-0"
      alt=""
    />
    <img
      src="../assets/icons/we-make-icon.svg"
      class="h-20 md:h-fit absolute bottom-0 right-0"
      alt=""
    />
    <div class="container max-auto flex items-center justify-center">
      <p class="font-bold text-DarkBlueGrey text-xl md:text-3xl max-w-3xl text-center">
        We make your non-core tasks our core task keeping your business process
        functioning at a 100.
      </p>
    </div>
  </div>
  <div class="container max-auto">
    <hr />
  </div>
</template>

<script>
export default {
  name: "WeMakeYourNoneCore",
};
</script>
