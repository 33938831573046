<template>
  <div class="mb-10">
    <Navbar />
    <HomeViewHeader />
    <Services />
    <VisionAndMission />
    <Partners />
    <!-- <SendUsAmail /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import HomeViewHeader from "@/components/HomeViewHeader.vue";
import Services from "@/components/Services.vue";
import VisionAndMission from "@/components/VisionAndMission.vue";
import Partners from "@/components/Partners.vue";
import SendUsAmail from "@/components/SendUsAmail.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    Navbar,
    HomeViewHeader,
    Services,
    VisionAndMission,
    Partners,
    SendUsAmail,
    Footer,
  },
};
</script>
