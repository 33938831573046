<template>
  <div class="container max-auto py-10">
    <div class="flex flex-col space-y-5 md:space-y-0 md:flex-row items-start">
      <div class="w-full md:w-[30%]">
        <h3 class="font-bold text-md mt-10 mb-4 md:mb-10">SERVICES</h3>
        <div class="flex flex-col space-y-4">
          <router-link
            to="/front-office-solution"
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
          >
            Front Office Solutions
          </router-link>
          <router-link
            to="/back-office-solution"
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
            >Back Office Solutions</router-link
          >
          <router-link
            to="/health-care-solution"
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
            >Health Care/Telemedicine BPO
          </router-link>
          <router-link
            to="/logistics-and-ware-housing-solution"
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
          >
            Logistics and Warehousing
          </router-link>
        </div>
      </div>
      <div class="w-full md:w-[20%]">
        <h3 class="font-bold text-md mt-10 mb-4 md:mb-10">COMPANY</h3>
        <div class="flex flex-col justify-start items-start space-y-4">
          <router-link
            to="/about-us"
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
            >About</router-link
          >
          <router-link
            to="/contact-us"
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
          >
            Contact
          </router-link>
          <router-link
            to=""
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
            >Career
          </router-link>
          <router-link
            to=""
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
          >
            Process
          </router-link>
        </div>
      </div>
      <div class="w-full md:w-[15%]">
        <h3 class="font-bold text-md mt-10 mb-4 md:mb-10">SOCIAL</h3>
        <div class="flex flex-col justify-start items-start space-y-4">
          <router-link
            to=""
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
            >LinkdIn</router-link
          >
          <router-link
            to=""
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
          >
            Twitter
          </router-link>
          <router-link
            to=""
            class="text-[13px] text-GreyChateau hover:underline hover:text-DarkBlueGrey transition-all duration-1000"
            >Facebook
          </router-link>
        </div>
      </div>
      <div class="w-full md:w-[45%]">
        <h3 class="font-bold text-md mt-10 mb-4 md:mb-10">INFORMATION</h3>
        <div class="flex flex-col justify-start items-start space-y-4">
          <span
            class="text-[13px] text-GreyChateau hover:text-DarkBlueGrey transition-all duration-1000"
          >
            Ukraine: UNIQA Park Tower. Velyka Arnauts’ka St, 72/74, Odesa,
            Odessa Oblast, Ukraine 65000
          </span>
          <span
            class="text-[13px] text-GreyChateau hover:text-DarkBlueGrey transition-all duration-1000"
          >
            USA: 125 NW 11th Avenue Dania Beach FL 33004
          </span>
          <span
            class="text-[13px] text-GreyChateau hover:text-DarkBlueGrey transition-all duration-1000"
            >USA 2: 190 Bluegrass Valley Pkwy Alpharetta, GA 30005
          </span>
          <span
            class="text-[13px] text-GreyChateau hover:text-DarkBlueGrey transition-all duration-1000"
            >Nigeria: FHA Estate, Guzape, Abuja
          </span>
          <span
            class="text-[13px] text-GreyChateau hover:text-DarkBlueGrey transition-all duration-1000"
            >Germany: Munich DE. 3, Stahlgruberring
          </span>
          <router-link
            to=""
            class="flex flex-row items-start space-x-1 text-[13px] text-GreyChateau hover:text-DarkBlueGrey transition-all duration-1000"
          >
            <span>T:</span>
            <div class="flex flex-col space-y-2">
              <span> USA (616) 244-9316</span>
              <span> NGN (+234) 818-887-2426</span>
            </div>
          </router-link>
          <a
            class="text-[13px] text-GreyChateau hover:text-DarkBlueGrey transition-all duration-1000"
            href="mailto:info@loremsupport.com"
            >E: info@loremsupport.com</a
          >
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-28 mb-16">
      <img src="../assets/icons/loremsupport-dark-logo.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
