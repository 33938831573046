<template>
  <div class="relative">
    <img
      src="../assets/icons/chat-service-left-icon.svg"
      class="hidden md:block absolute -bottom-20 left-0"
      alt=""
    />
    <div
      class="container mx-auto flex flex-col-reverse items-center justify-center space-y-5 md:space-y-0 md:flex-row my-20 md:space-x-10"
    >
      <div class="w-full md:w-1/2 space-y-6">
        <p class="font-bold text-3xl max-w-lg">Phone Support</p>
        <p class="text-md max-w-lg leading-[2rem]">
          Transform your site's visitors into paying customers. With our 24/7
          live chat service, be rest assured that your services are receiving an
          even greater exposure.
        </p>
        <div class="flex flex-col space-y-4 text-MistBlue">
          <div class="flex flex-row items-center space-x-2">
            <img src="../assets/icons/support-icon.svg" alt="" />
            <p>Live chat professionals</p>
          </div>
          <div class="flex flex-row items-center space-x-2">
            <img src="../assets/icons/support-icon.svg" alt="" />
            <p>Reports & performance optimisation</p>
          </div>
          <div class="flex flex-row items-center space-x-2">
            <img src="../assets/icons/support-icon.svg" alt="" />
            <p>Customer specific insights</p>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <img
          src="../assets/images/chat-service-img.png"
          class="object-cover mb-4"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatServices",
};
</script>
