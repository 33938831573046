<template>
  <div>
    <div
      class="container mx-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row my-20 md:space-x-10"
    >
      <div class="w-full md:w-1/2">
        <div class="flex flex-row items-center space-x-4 mb-10">
          <div
            class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
          ></div>
          <h3 class="text-sm md:text-lg font-medium tracking-[3px]">
            LOGISTICS AND WAREHOUSING
          </h3>
        </div>
        <div class="block md:hidden mb-4">
          <img
            src="../assets/images/logistics-header-img.png"
            class="object-cover"
            alt=""
          />
        </div>
        <p class="font-bold text-2xl md:text-4xl md:leading-[3rem] mb-16">
          Create a memorable customer experience which benchmarks your business
          from the competition.
        </p>
        <p class="text-xl max-w-lg mb-4 md:mb-10">
          For efficient logistics to be possible, top customer service can’t be
          undermined, because it is more often than not the “Backbone” of any
          business.
        </p>
        <div class="flex flex-row items-center space-x-4">
          <GetAQuoteButton text="GET A QUOTE" color="#d1643a" />
          <BookACallButton />
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div class="hidden md:block">
          <img
            src="../assets/images/logistics-header-img.png"
            class="object-cover"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetAQuoteButton from "@/components/GetAQuoteButton.vue";
import BookACallButton from "@/components/BookACallButton.vue";
export default {
  name: "LogisticsAndWareHousingHeader",
  components: {
    GetAQuoteButton,
    BookACallButton,
  },
};
</script>
