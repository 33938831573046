<template>
  <div>
    <div class="container max-auto mt-16">
      <p
        class="font-bold text-lg md:text-5xl md:leading-[3.5rem] max-w-5xl mb-16"
      >
        Our back office services at Lorem Support provides you with streamlined,
        integrated systems consisting of:
      </p>
    </div>
    <div
      class="bg-DarkBlueGrey text-white rounded-2xl py-10 md:py-24 mx-1 md:mx-8 text-center"
    >
      <div class="container mx-auto">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-10 mb-10">
          <div class="flex flex-col items-center space-y-6">
            <img
              src="../assets/icons/market-research-icon.svg"
              class="w-16 h-16 md:w-20 md:h-20 cursor-pointer p-5 bg-Champagne rounded-md"
              alt=""
            />
            <p class="text-sm md:text-base">Market Research</p>
          </div>
          <div class="flex flex-col items-center space-y-6">
            <img
              src="../assets/icons/dark-mining-icon.svg"
              class="w-16 h-16 md:w-20 md:h-20 cursor-pointer p-5 bg-SnowFlurry rounded-md"
              alt=""
            />
            <p class="text-sm md:text-base">Data Mining</p>
          </div>
          <div class="flex flex-col items-center space-y-6">
            <img
              src="../assets/icons/data-entry-icon.svg"
              class="w-16 h-16 md:w-20 md:h-20 cursor-pointer p-5 bg-Ice rounded-md"
              alt=""
            />
            <p class="text-sm md:text-base">Data Entry</p>
          </div>
          <div class="flex flex-col items-center space-y-6">
            <img
              src="../assets/icons/documentation-and-verification-icon.svg"
              class="w-16 h-16 md:w-20 md:h-20 cursor-pointer p-5 bg-PaleMauve rounded-md"
              alt=""
            />
            <p class="text-sm md:text-base">Documentation and Verification</p>
          </div>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-10 md:gap-0">
          <div class="flex flex-col items-center space-y-6">
            <img
              src="../assets/icons/billing-icon.svg"
              class="w-16 h-16 md:w-20 md:h-20 cursor-pointer p-5 bg-Fog rounded-md"
              alt=""
            />
            <p class="text-sm md:text-base">Billing</p>
          </div>
          <div class="flex flex-col items-center space-y-6">
            <img
              src="../assets/icons/clain-processing-icon.svg"
              class="w-16 h-16 md:w-20 md:h-20 cursor-pointer p-5 bg-PastelPink rounded-md"
              alt=""
            />
            <p class="text-sm md:text-base">Clain processing</p>
          </div>
          <div class="flex flex-col items-center space-y-6">
            <img
              src="../assets/icons/cost-control-icon.svg"
              class="w-16 h-16 md:w-20 md:h-20 cursor-pointer p-5 bg-Geyser rounded-md"
              alt=""
            />
            <p class="text-sm md:text-base">Cost Control Analysis</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackOfficeServices",
};
</script>
