<template>
  <div class="container mx-auto my-20">
    <div class="flex flex-row items-center space-x-5 mb-10">
      <div
        class="bg-gradient-to-r from-Terracotta to-Cashmere w-10 h-3.5"
      ></div>
      <h3 class="text-lg font-medium">LOREM SUPPORT SERVICES</h3>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
      <!-- Front OFFICE SOLUTIONS -->
      <div class="mx-4 sm:mx-0 px-8 py-10 bg-Iron">
        <router-link
          to="/front-office-solution"
          class="grid grid-rows-3 gap-10 h-full"
        >
          <div class="flex justify-between">
            <div class="flex flex-col text-sm md:text-base font-bold">
              <h4>FRONT OFFICE</h4>
              <h4>SOLUTIONS</h4>
            </div>
            <img
              src="../assets/icons/Arrow-Right-dark.svg"
              class="w-12 h-12"
              alt=""
            />
          </div>
          <div class="">
            <img
              src="../assets/icons/front-office-solution-icon.svg"
              class="w-16 h-16 md:w-20 md:h-24"
              alt=""
            />
          </div>
          <div class="flex flex-col">
            <p class="font-semibold text-md md:text-[22px]">Phone Support,</p>
            <p class="font-semibold text-md md:text-[22px]">Email Support,</p>
            <p class="font-semibold text-md md:text-[22px]">Chat Services</p>
          </div>
        </router-link>
      </div>

      <!-- BACK OFFICE SOLUTIONS -->
      <div class="mx-4 sm:mx-0 px-8 py-10 bg-DarkBlueGrey text-white">
        <router-link
          to="/back-office-solution"
          class="grid grid-rows-3 gap-8 md:gap-10 h-full"
        >
          <div class="flex justify-between">
            <div class="flex flex-col text-sm md:text-base font-bold">
              <h4>BACK OFFICE</h4>
              <h4>SOLUTIONS</h4>
            </div>
            <img
              src="../assets/icons/Arrow-Right-2.svg"
              class="w-12 h-12"
              alt=""
            />
          </div>
          <div class="">
            <img
              src="../assets/icons/back-office-solution-icon.svg"
              class="w-16 h-16 md:w-20 md:h-24"
              alt=""
            />
          </div>
          <div class="flex flex-col">
            <p class="font-semibold text-md md:text-[22px]">
              Data Collection and
            </p>
            <p class="font-semibold text-md md:text-[22px]">
              Processing, Insights,
            </p>
            <p class="font-semibold text-md md:text-[22px]">
              Analytics, Machine learning
            </p>
          </div>
        </router-link>
      </div>

      <!-- HEALTHCARE TELEMEDICINE BPO -->
      <div class="mx-4 sm:mx-0 px-8 py-10 bg-MandysPink">
        <router-link
          to="/health-care-solution"
          class="grid grid-rows-3 gap-8 md:gap-10 h-full"
        >
          <div class="flex justify-between">
            <div class="flex flex-col text-sm md:text-base font-bold">
              <h4>HEALTHCARE/</h4>
              <h4>TELEMEDICINE</h4>
              <h4>BPO</h4>
            </div>
            <img
              src="../assets/icons/Arrow-Right-dark.svg"
              class="w-12 h-12"
              alt=""
            />
          </div>
          <div class="">
            <img
              src="../assets/icons/healthcare-telemedcine-icon.svg"
              class="w-16 h-16 md:w-20 md:h-24"
              alt=""
            />
          </div>
          <div class="flex flex-col">
            <p class="font-semibold text-md md:text-[22px]">
              Sales through service,
            </p>
            <p class="font-semibold text-md md:text-[22px]">
              Customer Selection,
            </p>
            <p class="font-semibold text-md md:text-[22px]">
              Customer Retention
            </p>
          </div>
        </router-link>
      </div>

      <!-- FRONT OFFICE SOLUTION -->
      <div class="mx-4 sm:mx-0 px-8 py-10 bg-BleachWhite">
        <router-link
          to="/logistics-and-ware-housing-solution"
          class="grid grid-rows-3 gap-8 md:gap-10 h-full"
        >
          <div class="flex justify-between">
            <div class="flex flex-col text-sm md:text-base font-bold">
              <h4>LOGISTICS AND WAREHOUSING</h4>
              <h4>SUPPORT SOLUTION</h4>
            </div>
            <img
              src="../assets/icons/Arrow-Right-dark.svg"
              class="w-12 h-12"
              alt=""
            />
          </div>
          <div class="">
            <img
              src="../assets/icons/frontt-office-solution-icon.svg"
              class="w-16 h-16 md:w-20 md:h-20"
              alt=""
            />
          </div>
          <div class="flex flex-col">
            <p class="font-semibold text-md md:text-[22px]">Logistics and</p>
            <p class="font-semibold text-md md:text-[22px]">Warehousing</p>
            <p class="font-semibold text-md md:text-[22px]">Solutions</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
